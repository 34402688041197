import { Grid, TextField, Select, MenuItem } from "../../../../components";

const FormFiltroAvancado = ({ valueInput, onChangeInput }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="codigo"
          name="codigo"
          label="Código"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("codigo")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="codigoBarra"
          name="codigoBarra"
          label="Código Barras"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("codigoBarra")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descricao")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="tipo"
          name="tipo"
          variant="outlined"
          label="Tipo"
          fullWidth
          onChange={(event) => onChangeInput(event)}
        >
          <MenuItem value="simples">Simples</MenuItem>
          <MenuItem value="grade">Grade</MenuItem>
          <MenuItem value="kit">Kit</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
